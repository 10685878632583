import React from "react";
import banner from "../assets/responsive-website-design.webp";

const HomePageBanner = () => {
  return (
    <section className="banner">
      <div className="banner-content">
        <h1>Custom Web Design and more..</h1>
        <p>Professional, unique, and tailored to your needs.</p>
        <a href="#services" className="btn">
          Explore Services
        </a>
      </div>
      <img src={banner} alt="My Image" width="570" height="339" />
    </section>
  );
};

export default HomePageBanner;
