import React from "react";
import HomePageBanner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import WhyChooseUs from "../components/WhyChooseUs";
import OurProcess from "../components/OurProcess";
import SuccessStories from "../components/SuccessStories";
import Testimonials from "../components/Testimonials";
import Portfolio from "../components/Portfolio";
import FAQ from "../components/FAQ";
import GetStarted from "../components/GetStarted";

const HomePage = () => {
  return (
    <div>
      <HomePageBanner />
      <WhyChooseUs />
      <OurProcess />
      <SuccessStories />
      <div id="signup">
        <Testimonials />
        <GetStarted />
      </div>
      <FAQ />

      <AboutUs />
    </div>
  );
};

export default HomePage;
