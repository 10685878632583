import React from "react";

const GetStarted = () => {
  return (
    <section className="get-started">
      <div class="container">
        <h2>Get Started Today</h2>
        <p>
          Ready to elevate your online presence with a professional website?
          Contact us today to schedule a consultation and discover how DVK
          Designs can help you achieve your business goals. Fill out our contact
          form or call us at (954) 249-8837 get started. Let's build something
          amazing together!
          <br />
          <br />
        </p>
        <a
          href="https://calendly.com/dvkdesign/30min"
          target="_blank"
          className="btn"
        >
          Schedule a Consultation
        </a>
      </div>
    </section>
  );
};

export default GetStarted;
