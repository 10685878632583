import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import useParams hook
import { marked } from "marked";

const BlogPost = () => {
  const { post } = useParams(); // Get the 'post' parameter from the URL
  const [content, setContent] = useState("");

  useEffect(() => {
    // Dynamically import the markdown file based on the 'post' URL parameter
    import(`../blogPosts/${post}.md`)
      .then((module) => fetch(module.default))
      .then((response) => response.text())
      .then((text) => setContent(marked(text)));
  }, [post]);

  return (
    <div>
      <h1>Blog Post</h1>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default BlogPost;
