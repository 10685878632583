import React from "react";
import BandsPageBanner from "../components/BandsPage/Banner";
import BandsContent from "../components/BandsPage/Content";

const BandsPage = () => {
  return (
    <div>
      <BandsPageBanner />

      <BandsContent />
    </div>
  );
};

export default BandsPage;
