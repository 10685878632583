import React from "react";

const ServicesPage = () => {
  return (
    <div className="container page">
      <h1>Our Services</h1>
      <ul>
        <li>Custom Website Design</li>
        <li>Responsive Web Development</li>
        <li>E-commerce Solutions</li>
        <li>SEO & Digital Marketing</li>
      </ul>
    </div>
  );
};

export default ServicesPage;
