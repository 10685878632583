import React from "react";
import logo from "../assets/logo.webp";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer-content">
        <div className="footer-section logo">
          <img src={logo} alt="My Image" className="logo-img" width="150" />
        </div>

        <div className="footer-section contact-info">
          <h4>Contact Us</h4>
          <p>Email: danielvkariamis@gmail.com</p>
          <p>Phone: (954) 249-8837</p>
          <p>Address: Parkland, Florida 33067</p>
        </div>

        <div className="footer-section social-links">
          <h4>Follow Us</h4>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/dvkdesigns/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 DVK Designs. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
