import React from "react";

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div class="container">
        <h2>What Our Clients Say</h2>
        <div className="testimonial">
          <p>
            "Thanks for a great job! Wow Bounce is extremely thankful and fully
            recommends DVK Designs for your website design needs. They worked
            non-stop until we were fully satisfied and even took the time to
            provide hands on training. Thanks again!"
          </p>
          <cite>Sergio Esquival, Wow Bounce</cite>
        </div>
        <div className="testimonial">
          <p>
            "I just want to thank Dan for his amazing work on my new site. It''s
            beautiful, clean and perfect! You were a pleasure to work with and
            was always so quick to answer my questions. You did an amazing job!
            Thank you so much!"
          </p>
          <cite>Mylene, ML Outdoor Furnishing</cite>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
