import React from "react";
import impactKids from "../assets/impact-kids.webp";
import ivfmd from "../assets/ivfmd.webp";
import epk from "../assets/electronic-press-kits-by-dvkdesigns.webp";

const SuccessStories = () => {
  return (
    <section id="blog" className="success-stories">
      <div class="container">
        <h2>Success Stories</h2>
        <div className="case-study">
          <img src={impactKids} alt="My Image" width="300" height="375" />
          <div class="study-copy">
            <h3>Impact Kids</h3>
            <p>
              Impact Kids came to us wanting a uniqe and fun website for their
              Pediatric Therapy for Children website. They requestion colorful
              and inclusive website with simple Call to action, and the need to
              rank locally in search engines.
              <ul>
                <li>
                  Custom Design: We created a sleek, colorful design that
                  reflected the brand's identity and appealed to their target
                  audience.
                </li>
                <li>
                  SEO Optimization: Our SEO strategy helped them rank higher in
                  search engine results, driving more organic traffic to their
                  site.
                </li>
                <li>
                  Responsive Design: The website looked and functioned perfectly
                  on all devices, providing a great user experience. Results:
                  Within six months of launching the new site, Impact Kids saw a
                  50% increase in phone calls and a significant boost in their
                  search engine rankings.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="case-study">
          <img src={ivfmd} alt="My Image" width="300" height="508" />
          <div class="study-copy">
            <h3>IVFMD of South Florida</h3>
            <p>
              IVFMD approached us with the goal of launching a new website to
              replace their outdated version. While they had a lot of input in
              the creativity of the site we managed to assemble a top website
              package helped them achieve their goals:
              <ul>
                <li>
                  Custom Design: We created a sleek, modern design that
                  reflected the brand's identity and appealed to their target
                  audience.
                </li>
                <li>
                  Responsive Design: The website looked and functioned perfectly
                  on all devices, providing a great user experience. Results:
                  Within six months of launching the new site, IVFMD saw a 5%
                  increase in online sales and a significant boost in their
                  search engine rankings.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="case-study">
          <img src={epk} alt="My Image" width="300" height="300" />
          <div class="study-copy">
            <h3>
              <a href="/bandspage">
                Why Bands and Artists Need a Website, EPK, Professional Logo,
                Graphic Design, Photography, and Video
              </a>
            </h3>
            <p>
              In today’s fast-paced, digital-first world, the music industry
              thrives on how well artists can market themselves. The days of
              relying solely on record labels are long gone. Now, musicians must
              build their own brands and make a name for themselves. To do that,
              bands and solo artists need more than just good music—they need a
              robust online presence with a website,{" "}
              <a href="/bandspage">Electronic Press Kit (EPK)</a>, professional
              band logo, graphic design, high-quality band photography, and band
              video content. Here’s why all these elements are crucial for
              success in the music industry.{" "}
              <a href="/bandspage">Continue Reading</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;
