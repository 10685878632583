import React from "react";

const FAQ = () => {
  return (
    <section className="faq">
      <div class="container">
        <h2>Frequently Asked Questions</h2>
        <div className="question">
          <h3>Why should I invest in a website?</h3>
          <p>
            Investing in a website ensures you receive a high-quality,
            custom-built site that meets your specific needs and goals. A
            professional website can help you attract more customers, improve
            your online presence, and ultimately grow your business. The
            investment also includes ongoing support and maintenance, ensuring
            your site remains up-to-date and secure.
          </p>
        </div>
        <div className="question">
          <h3>How long does it take to build a website?</h3>
          <p>
            The timeline for building a website varies depending on the
            complexity of the project and your specific requirements. On
            average, the process takes about 6-8 weeks from the initial
            consultation to the launch. We provide a detailed project plan and
            timeline during the discovery phase to keep you informed every step
            of the way.
          </p>
        </div>
        <div className="question">
          <h3>Can I update the website myself?</h3>
          <p>
            Yes, we build our websites using user-friendly content management
            systems (CMS) like WordPress, Shopify, or Magento. We provide
            training to help you manage and update your site easily. However, if
            you prefer, we also offer ongoing support and maintenance services
            to handle updates for you.
          </p>
        </div>
        <div className="question">
          <h3>What if I need additional features or services?</h3>
          <p>
            We understand that every business has unique needs, and we're
            flexible in accommodating additional features or services. During
            the discovery phase, we'll discuss your specific requirements and
            provide a customized proposal. If you need additional features after
            the site is launched, we're always available to assist with
            enhancements and updates.
          </p>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
