import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // still useful for routing to other pages
import logo from "../assets/logo.webp";
import { FaPhone } from "react-icons/fa6";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scrolling effect for header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className="container">
        <h1 className="logo">
          <Link to="/">
            <img src={logo} alt="My Image" width="300" height="51" />
          </Link>
        </h1>
        <nav>
          <ul>
            {/* Use regular anchor tags for internal page anchor links */}
            <li>
              <a href="#services">Services</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#blog">Blog</a>
            </li>
            <li>
              <a href="#footer">Contact</a>
            </li>
          </ul>
        </nav>
        {/* Fixed 'class' to 'className' */}
        <a className="phone" href="tel:1-954-249-8837">
          <FaPhone /> 954-249-8837
        </a>
      </div>
    </header>
  );
};

export default Header;
