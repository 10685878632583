import React from "react";
import customWebDesign from "../assets/custom-web-design.webp";
import seo from "../assets/search-engine-optimization.webp";
import responsiveWebDesign from "../assets/responsive-website-design2.webp";
import ecommerceWebDesign from "../assets/E-Commerce-Transparent.webp";
import securityPerformance from "../assets/securityvsperformance-696x392.webp";

import {
  FaPaintBrush,
  FaSearch,
  FaMobileAlt,
  FaShoppingCart,
  FaLock,
  FaLifeRing,
} from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <section id="services" className="why-choose-us">
      <div class="container">
        <h2>Why Choose Us for your Website Package?</h2>
        <div className="features">
          <div className="feature">
            <img
              src={customWebDesign}
              alt="My Image"
              width="293"
              height="214"
            />
            <h3>Custom Design Tailored to Your Brand</h3>
            <p>
              Your website is the face of your business online, and first
              impressions matter. Our team of experienced designers works
              closely with you to create a custom design that reflects your
              brand's unique identity and appeals to your target audience. We
              don't use templates or one-size-fits-all solutions. Instead, we
              craft each website from scratch, ensuring that your site stands
              out from the competition and leaves a lasting impression on
              visitors.
            </p>
          </div>
          <div className="feature">
            <img src={seo} alt="My Image" width="293" height="214" />
            <h3>Advanced SEO Optimization</h3>
            <p>
              A beautiful website is worthless if it can't be found by your
              target audience. Our website package includes advanced SEO
              optimization to boost your visibility on search engines. We
              conduct thorough keyword research, optimize your content and meta
              tags, and implement best practices for on-page SEO. By improving
              your search engine rankings, we help you attract more organic
              traffic and potential customers to your site.
            </p>
          </div>
          <div className="feature">
            <img
              src={responsiveWebDesign}
              alt="My Image"
              width="293"
              height="214"
            />
            <h3>Responsive Design for All Devices</h3>
            <p>
              In today's mobile-driven world, it's essential that your website
              looks and functions flawlessly on all devices. Our websites are
              built with responsive design, ensuring a seamless user experience
              whether visitors are browsing on a desktop, tablet, or smartphone.
              This not only enhances user satisfaction but also positively
              impacts your search engine rankings, as Google prioritizes
              mobile-friendly websites.
            </p>
          </div>
          <div className="feature">
            <img
              src={ecommerceWebDesign}
              alt="My Image"
              width="293"
              height="214"
            />
            <h3>E-commerce Integration</h3>
            <p>
              If you're looking to sell products or services online, our $5,000
              website package includes advanced e-commerce integration. We set
              up and customize powerful e-commerce platforms like Shopify,
              WooCommerce, or Magento to suit your business needs. From product
              listings and shopping carts to secure payment gateways and order
              management, we provide everything you need to run a successful
              online store.
            </p>
          </div>
          <div className="feature">
            <img
              src={securityPerformance}
              alt="My Image"
              width="293"
              height="214"
            />
            <h3>Security and Performance</h3>
            <p>
              A secure and fast-loading website is crucial for both user
              experience and search engine rankings. We implement top-notch
              security measures to protect your site from cyber threats and
              ensure that your customers' data is safe. Additionally, we
              optimize your website's performance to ensure fast loading times,
              reducing bounce rates and keeping visitors engaged.
            </p>
          </div>
          <div className="feature">
            <FaLifeRing />
            <h3>Ongoing Support</h3>
            <p>
              Launching your website is just the beginning. We offer
              comprehensive support and maintenance packages to keep your
              website running smoothly and up-to-date. Our team is always
              available to assist with any technical issues, updates, or
              enhancements you may need. With our ongoing support, you can focus
              on running your business while we take care of your website.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
