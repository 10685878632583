import React from "react";
import myImage from "../assets/code.webp";

const AboutUs = () => {
  return (
    <div id="about" class="about-us">
      <section>
        <img src={myImage} alt="My Image" width="921" height="580" />
        <div class="about">
          <h2>About DVK Designs</h2>
          <p>
            Welcome to DVK Designs, your trusted partner in digital
            transformation. Our mission is to help businesses of all sizes
            elevate their online presence through custom-designed websites and
            comprehensive digital marketing services. We are committed to
            delivering exceptional quality, innovative solutions, and measurable
            results that drive business growth.
          </p>
          <h3>Who We Are</h3>
          <p>
            DVK Designs was founded with a clear vision: to provide businesses
            with top-notch digital solutions that are tailored to their unique
            needs. Our team is composed of experienced professionals who are
            passionate about web design, development, and digital marketing. We
            bring a wealth of knowledge and expertise to every project, ensuring
            that our clients receive the best possible service and support.
          </p>
          <h4>Our Values</h4>
          <ul>
            <li>
              <strong>Quality</strong>: We are dedicated to delivering the
              highest quality services and products. Our attention to detail and
              commitment to excellence ensures that our clients receive websites
              that are not only visually stunning but also functionally robust.
            </li>
            <li>
              <strong>Innovation</strong>: The digital landscape is constantly
              evolving, and so are we. We stay ahead of the curve by adopting
              the latest technologies and best practices in web design and
              digital marketing. Our innovative solutions help our clients stay
              competitive and achieve their business goals.
            </li>
            <li>
              <strong>Customer Focus</strong>: Our clients are at the heart of
              everything we do. We take the time to understand their needs,
              challenges, and goals, and we work closely with them to develop
              customized solutions that deliver real results.
            </li>
            <li>
              <strong>Integrity</strong>: We believe in building long-term
              relationships based on trust and transparency. We are honest,
              ethical, and committed to doing what is right for our clients.
            </li>
            <li>
              <strong>Collaboration</strong>: Great things happen when we work
              together. We foster a collaborative environment where ideas are
              shared, and creativity thrives. Our team works closely with
              clients to ensure their vision is brought to life.
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
