import React from "react";

const OurProcess = () => {
  return (
    <section className="our-process">
      <div class="container">
        <h2>Our Process</h2>
        <p>
          Creating a website that delivers real results involves a detailed and
          collaborative process. Here's a step-by-step overview of how we work
          with you to bring your vision to life:
        </p>
        <div className="steps">
          <div className="step">
            <h3>Step 1: Discovery and Consultation</h3>
            <p>
              We start with a thorough discovery phase to understand your
              business, goals, target audience, and competitors. During our
              initial consultation, we discuss your vision for the website, the
              features you need, and any specific requirements you may have.
              This helps us create a detailed project plan and timeline.
            </p>
          </div>
          <div className="step">
            <h3>Step 2: Planning and Strategy</h3>
            <p>
              Based on our discovery findings, we develop a comprehensive
              strategy for your website. This includes defining the site
              architecture, user experience (UX) design, content strategy, and
              SEO plan. We also create wireframes and mockups to visualize the
              layout and structure of your site.
            </p>
          </div>
          <div className="step">
            <h3>Step 3: Design and Development</h3>
            <p>
              Once the planning phase is complete, our design team gets to work
              creating a custom design that aligns with your brand and goals. We
              ensure that the design is visually appealing, user-friendly, and
              responsive. After you approve the design, our developers start
              building the site using the latest technologies and best
              practices.
            </p>
          </div>
          <div className="step">
            <h3>Step 4: Content Creation and Integration</h3>
            <p>
              High-quality content is essential for engaging visitors and
              improving SEO. We work with you to create compelling and relevant
              content for your website. This includes copywriting, images,
              videos, and any other media you need. We then integrate the
              content into the site, ensuring it aligns with the overall design
              and user experience.
            </p>
          </div>
          <div className="step">
            <h3>Step 5: Testing and Quality Assurance</h3>
            <p>
              Before launching your website, we conduct thorough testing to
              ensure everything works perfectly. This includes checking for any
              bugs, broken links, or performance issues. We also test the site
              on various devices and browsers to ensure a consistent experience
              for all users.
            </p>
          </div>
          <div className="step">
            <h3>Step 6: Launch and Training</h3>
            <p>
              Once the site passes all quality assurance tests, we prepare for
              the launch. We handle all the technical aspects of the launch,
              including domain setup, hosting configuration, and SEO setup. We
              also provide training to help you manage and update your site
              using the content management system (CMS).
            </p>
          </div>
          <div className="step">
            <h3>Step 7: Ongoing Support and Maintenance</h3>
            <p>
              After your website is live, we continue to support you with
              ongoing maintenance and updates. Whether you need to add new
              features, update content, or troubleshoot issues, our team is here
              to help. We offer various support packages to suit your needs and
              ensure your website remains in top condition.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProcess;
